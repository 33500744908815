import './App.scss';
import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import TestimonialForm from './components/Student';
import TestimonialResponse from './components/Tutor';
import Published from './components/Published';
import { getQuery } from './utils/functions';
import axios from './utils/axios-wl'
import i18n from './i18n';
import "./font.css";
import { getFont } from "./utils/fontUtil.js";


function App() {
  const token = getQuery()['token'] || localStorage.getItem('TestimonialToken');


  React.useEffect(() => {
    if (token) {
      localStorage.setItem('TestimonialToken', token);
    }
    let defaultLanguage = getQuery()['defaultLanguage']
    if(defaultLanguage) {
      i18n.changeLanguage(defaultLanguage?.substring(0,2)?.toLowerCase() || "en")
      axios.defaults.headers.common['accept-language'] = defaultLanguage?.toLowerCase() || "en"
    }
    function setFontfamily () {
      document.getElementById("root").style.setProperty('--font-family', getFont())
    }
    setFontfamily()
  }, []);

  const font = getFont()

  return (
    <BrowserRouter basename="/testimonials">
      <Suspense fallback={<h1>...</h1>}>
        <div style={{fontFamily: font}}>
        <Switch>
          <Route exact path={`/`} component={TestimonialForm} />
          <Route exact path={`/tutorHome`} component={TestimonialResponse} />
          <Route exact path={`/published`} component={Published} />
          <Redirect to={`/`} />
        </Switch>
        </div>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

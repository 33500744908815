let IMAGE_URL =
  "https://cdn-cp-assets-public.classplus.co/classplus-websites/common/images/donation-images";
let BASE_URL = "https://api.classplusapp.com";
if (process.env.REACT_APP_NODE_ENV === "development") {
  BASE_URL = "https://api.classplusapp.com";
} else {
  if (process.env.REACT_APP_NODE_ENV === "staging") {
    BASE_URL = "https://api.classplusapp.com";
  } else if (process.env.REACT_APP_NODE_ENV === "production") {
    BASE_URL = "https://api.classplusapp.com";
  } else if (process.env.REACT_APP_NODE_ENV === "qa") {
    BASE_URL = "https://apip-qa.classplusapp.com";
  }
}

export default {
  BASE_URL,
  IMAGE_URL,
};
